<template>
  <v-card>
    <div class="pa-5 mb-5">
      <v-card-title
        primary-title
        class="title"
      >
        Recherche un établissement
      </v-card-title>
      <v-form
        @submit.prevent="search()"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="filterType"
              :items="filterTypes"
              label="Type de recherche"
              clearable
              @input="search()"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="query"
              label="Recherche"
              clearable
              data-vv-name="recherche"
              @input="search()"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-btn
              type="submit"
              color="primary"
              v-text="'Rechercher'"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'FilterEtablissementScolaireComponent',
  data() {
    return {
      query: '',
      filterType: null,
      filterTypes: [
        { text: 'Nom'      , value: 'nom'                 },
        { text: 'Commune'  , value: 'adresse.commune.nom' },
        { text: 'Nom (CL)' , value: 'cl.nom'              },
        { text: 'Code (CL)', value: 'cl.code'             },
      ],
    };
  },
  computed: {
    ...mapGetters('etablissementScolaire', {
      getFilter: 'getFilter',
    }),
  },
  created() {
    this.filterType = this.getFilter.type;
    this.query = this.getFilter.q;
  },
  methods: {
      ...mapMutations('etablissementScolaire', {
          setFilter: 'SET_FILTER',
      }),
    search() {
      this.setFilter({ type: this.filterType, q: this.query });
    },
  },
};
</script>
<template>
  <div>
    <v-card v-if="!isUserLocal">
      <div class="pa-5 mb-5">
        <v-alert
          color="primary"
          dense
          type="info"
          :value="!currentCdpeId"
        >
          Veuillez sélectionner un <strong>Département</strong>
        </v-alert>
        <v-row>
          <v-col v-if="isUserNational">
            <v-autocomplete
              v-model="currentCdpeId"
              label="Département"
              item-value="id"
              :items="filterDepartements"
              :item-text="(item) => `${item.code} - ${item.nom}`"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-if="currentCdpeId"
              v-model="currentClId"
              label="Conseil Local"
              item-value="id"
              clearable
              :items="filterCL"
              :item-text="(item) => `${item.code} - ${item.nom}`"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-data-table
      light
      :headers="headers"
      class="elevation-3"
      :items="etablissements"
      :sort-by="['nom']"
      :items-per-page="50"
      :loading="loading"
      loading-text="Chargement ..."
      no-data-text="Aucun établissement"
      :footer-props="{
        itemsPerPageText: 'Etablissements par page:',
        itemsPerPageOptions: [20, 50, 100, 200]
      }"
    >
      <template v-slot:item.action="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click.stop
            >
              more_vert
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              v-if="true"
              :to="{ name: 'view-etablissement', params: { etablissementId: item.id }}"
            >
              <v-list-item-action>
                Voir
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-if="canEdit"
              :to="{ name: 'edition-etablissement', params: { etablissementId: item.id }}"
            >
              <v-list-item-action>
                Modifier
              </v-list-item-action>
            </v-list-item>

            <v-list-item
              v-if="canDelete"
              @click.stop="askRemove(item)"
            >
              <v-list-item-action>
                Supprimer
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.cl="{ item }">
        {{ item.cl.code }} - {{ item.cl.nom }}
      </template>
    </v-data-table>
    <v-progress-linear
      v-if="loading"
      :indeterminate="true"
    />
    <ConfirmedModal
      v-if="etablissementToDelete"
      :sentence="`Supprimer l'établissement scolaire ${etablissementToDelete.nom} ?`"
      btn-action="Supprimer"
      @close="etablissementToDelete = null"
      @confirmed="remove"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import droits from '../../auth/droits.js';
import ConfirmedModal from '../reusableComponents/confirmedModal';

export default {
  name: 'ListEtablissementScolaireComponent',
  components: {
    ConfirmedModal,
  },
  data() {
    return {
      currentCdpeId: null,
      currentClId: null,
      etablissementToDelete: null,
      loading: false,
      headers: [
        { text: 'Action', value: 'action', sortable: false },
        { text: 'Nom', value: 'nom', sortable: true },
        { text: 'Conseil Local', value: 'cl', sortable: true },
        { text: 'Téléphone Fixe', value: 'contact.telephone_fixe', sortable: true },
        { text: 'Email', value: 'contact.email', sortable: true },
        { text: 'Commune', value: 'adresse.commune.nom', sortable: true },
      ],
    };
  },
  computed: {
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
      isUserDepartemental: 'isUserDepartemental',
      isUserLocal: 'isUserLocal',
      userCdpeId: 'getUserCdpeId',
      userClId: 'getUserConseilLocalId',
      getDroits: 'getDroits',
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),
    ...mapGetters('etablissementScolaire', {
      getEtablissements: 'getFilteredEtablissements',
      getPreviousCdpeId: 'getCdpeId',
      getPreviousClId: 'getClId',
    }),
    ...mapGetters('conseilLocal', {
      filterCL: 'getFilterConseilsLocaux',
    }),
    canEdit() {
      return this.getDroits.includes(droits.ETABLISSEMENT_MODIFICATION);
    },
    canDelete() {
      return this.getDroits.includes(droits.ETABLISSEMENT_SUPPRESSION);
    },
    etablissements() {
        return this.getEtablissements
            .filter(eta => !this.currentClId || eta.cl.id === this.currentClId);
    },
  },
  watch: {
    currentCdpeId(cdpeId) {
      this.currentClId = null;
      this.loading = true;
      Promise.all([
        this.loadCLs(cdpeId),
        this.loadEtablissements({ cdpeId }),
      ]).then(() => this.loading = false);
    },
    currentClId(val) {
      this.saveClId(val);
    },
  },
  created() {
    if (this.isUserNational) {
      this.loadFilterDepartements();
      this.currentCdpeId = this.getPreviousCdpeId;
    } else if(this.isUserDepartemental) {
      this.currentCdpeId = this.userCdpeId;
    } else if(this.isUserLocal) {
      this.currentCdpeId = this.userCdpeId;
      this.saveClId(this.userClId);
    }
    this.$nextTick(() => this.currentClId = this.getPreviousClId);
  },
  methods: {
    ...mapActions('cdpe', {
      loadFilterDepartements: 'loadFilterDepartements',
    }),
    ...mapActions('etablissementScolaire', {
      loadEtablissements: 'loadEtablissements',
      deleteEtablissement: 'deleteEtablissement',
    }),
    ...mapActions('conseilLocal', {
      loadCLs: 'loadFilterConseilsLocaux',
    }),
    ...mapMutations('etablissementScolaire', {
      saveClId: 'SET_CL_ID',
    }),
    askRemove(eta) {
      this.etablissementToDelete = eta;
    },
    remove() {
      this.deleteEtablissement({ etablissementId: this.etablissementToDelete.id })
        .then(() => this.etablissementToDelete = null);
    },
  },
};
</script>
<template>
  <div>
    <FilterEtablissementScolaireComponent />
    <ListEtablissementScolaireComponent />
  </div>
</template>

<script>
import FilterEtablissementScolaireComponent from '../../components/etablissementScolaire/FilterEtablissementScolaireComponent';
import ListEtablissementScolaireComponent from '../../components/etablissementScolaire/ListEtablissementScolaireComponent';

export default {
  name: 'SearchEtablissementScolaireContainer',
  components: {
    FilterEtablissementScolaireComponent,
    ListEtablissementScolaireComponent,
  },
  data() {
    return {

    };
  },
};
</script>